import React, { createContext, useRef, useState } from 'react';

// Translation JSON files
import hindi from '../locales/hindi.json';
import japanese from '../locales/japanese.json';
import spanish from '../locales/spanish.json';

// Default translations (English)
const defaultTranslations = {
  en: {},
  hi: hindi,
  es: spanish,
  jp: japanese,  
};

// Create Translation Context
const TranslationContext = createContext();

export const TranslationProvider = ({ children }) => {
  const [language, setLanguage] = useState('en');
  const translations = defaultTranslations;
  const missingTranslationsRef = useRef({}); 

  const t = (key) => {
    if (language === 'en') return key;
    // Check if translation for the current language exists
    if (translations[language] && translations[language][key]) {
      return translations[language][key];
    }

    // Track missing translations without causing re-render
    if (!missingTranslationsRef.current[language]) {
      missingTranslationsRef.current[language] = {};
    }

    if (!missingTranslationsRef.current[language][key]) {
      missingTranslationsRef.current[language][key] = key;
      // console.log('Updated Missing Translations:', missingTranslationsRef.current);
    }

    // Return the key (English value) as fallback
    return key;
  };

  const changeLanguage = (newLanguage) => {
    if (translations[newLanguage]) {
      setLanguage(newLanguage);
    } else {
      console.warn(`Language "${newLanguage}" not available`);
    }
  };

  return (
    <TranslationContext.Provider value={{ t, changeLanguage, language }}>
      {children}
    </TranslationContext.Provider>
  );
};

export const useTranslation = () => {
  const context = React.useContext(TranslationContext);
  if (!context) {
    throw new Error('useTranslation must be used within a TranslationProvider');
  }
  return context;
};
