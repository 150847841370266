import React, { useState } from 'react';
import Logo from "../../../../assets/images/Logo.svg";
import { useNavigate } from 'react-router-dom';
import ButtonGroup from './components/ButtonGroup';
import "./styles.scss";
import BusinessContent from './BusinessContent';

const ApplyBusinessLiteContent = () => {
    const navigate = useNavigate();
    const currentUrl = window.location.href;
    const urlObject = new URL(currentUrl);
    const queryParams = urlObject.search;
    const [sz, setSz] = useState(20);


    return (
        <div>
            <div className="fv_business_intro_container container py-4">
                {/* Desktop Layout (md and up) */}
                <div className='d-flex flex-column justify-content-center align-items-center'>
                    <img
                        src={Logo}
                        alt="fv-bank"
                        height="50"
                        className="logo logo-dark"
                    />
                    <div className="fv_indi-title">Business Plus</div>
                </div>

                <BusinessContent />

                <div className='mt-2'>
                    <ButtonGroup hideBackButton onNextClick={() => { console.log("hello"); navigate(`/business-plus/login${queryParams}`) }} onBackClick={() => { navigate(-1) }} step={2} asLabel={false} loading={false} limit={10} />
                </div>
            </div>

        </div>

    );
};

export default ApplyBusinessLiteContent;
