import React from 'react'
import "./styles.scss";

const BusinessContent = () => {

    const t = (v) => v

    const commonPoints = [
        'USD Depository account integrated with digital asset custody and Visa Corporate card',
        'Order business Visa debit cards for your business operators',
        'Effortlessly manage your bank account via a secure web portal and mobile app',
        'Send and receive payments in multiple currencies and stablecoins',
        'Digital assets-to-USD conversion and vice-versa'
    ];

    const plusExclusivePoints = [
        'Suitable for SMEs requiring an operational account for managing global business activities',
        'Lower limits on daily transactions and balance thresholds',
        'Open your Business Plus account in under 48 hours with a seamless and efficient onboarding process'
    ];

    const primeExclusivePoints = [
        <>
            Suitable for enterprises requiring client fund accounts, settlement accounts, trade accounts, or high-value transaction accounts.
            <br />
            <br />
            Suitable for Fintech and Blockchain companies.
        </>, 
        'Higher transaction limits and balance thresholds',
        'Upgrade from Business Plus to Business Prime within 1-2 weeks by submitting the required additional documents',
        'Optional access to Money Market funds',
        'Optional access to banking and payment APIs'
    ];

  return (
    <>
          <div className="fv_business_intro_desktop_comparison mt-0 d-none d-md-block bg-white px-2 shadow">
              <div className="fv_business_intro_desktop_row_header row bg-light fw-bold  py-2">
                  <div className="fv_business_intro_col col-6">Business Plus</div>
                  <div className="fv_business_intro_col col-6">Business Prime</div>
              </div>

              {/* 2-column row: Suitable for... */}
              <div className="fv_business_intro_row row    ">
                  <div className="fv_business_intro_col py-2 col-6">
                      {t(plusExclusivePoints[0])}
                  </div>
                  <div className="fv_business_intro_col right col-6 py-2 ps-2 ">
                      {t(primeExclusivePoints[0])}
                  </div>
              </div>

              {/* 5 common bullet points, each in a full-width row */}
              {commonPoints.map((point, idx) => (
                  <div
                      className="fv_business_intro_row_common row    "
                      key={`common-${idx}`}
                  >
                      <div className=" col-12 text-center py-2">
                          {t(point)}
                      </div>
                  </div>
              ))}

              {/* 2-column row: Lower vs Higher transaction limits */}
              <div className="fv_business_intro_row row    ">
                  <div className="fv_business_intro_col py-2 col-6">
                      {t(plusExclusivePoints[1])}
                  </div>
                  <div className="fv_business_intro_col py-2 right col-6">
                      {t(primeExclusivePoints[1])}
                  </div>
              </div>

              {/* 2-column row: Open vs Upgrade */}
              <div className="fv_business_intro_row row    ">
                  <div className="fv_business_intro_col py-2 col-6">
                      {t(plusExclusivePoints[2])}
                  </div>
                  <div className="fv_business_intro_col py-2 right col-6">
                      {t(primeExclusivePoints[2])}
                  </div>
              </div>

              {/* Prime-only points (Money Market, APIs) */}
              <div className="fv_business_intro_row  row    ">
                  <div className="fv_business_intro_col py-2 col-6" />
                  <div className="fv_business_intro_col  py-2 right col-6">
                      {t(primeExclusivePoints[3])}
                  </div>
              </div>
              <div className="fv_business_intro_row last row   ">
                  <div className="fv_business_intro_col  py-2 col-6" />
                  <div className="fv_business_intro_col py-2 right col-6">
                      {t(primeExclusivePoints[4])}
                  </div>
              </div>
          </div>

          {/* Mobile Layout (smaller than md) */}
          <div className="fv_business_intro_mobile_layout d-block d-md-none">
              {/* BUSINESS PLUS card */}
              <div className="fv_business_intro_mobile_card bg-white p-3 mb-3 rounded shadow">
                  <h4 className="fv_business_intro_card_title mb-3">Business Plus</h4>
                  {/* All Business Plus points (exclusive + common) */}
                  {plusExclusivePoints.map((point, idx) => (
                      <div key={`plus-${idx}`} className="fv_business_intro_mobile_item mb-2">
                          {t(point)}
                      </div>
                  ))}
                  {commonPoints.map((point, idx) => (
                      <div key={`common-plus-${idx}`} className="fv_business_intro_mobile_item mb-2">
                          {t(point)}
                      </div>
                  ))}
              </div>

              {/* BUSINESS PRIME card */}
              <div className="fv_business_intro_mobile_card bg-white p-3 rounded shadow">
                  <h4 className="fv_business_intro_card_title mb-3">Business Prime</h4>
                  <div className="fv_business_intro_mobile_item mb-2 fw-bold">
                      {t('Everything in Business Plus')}
                  </div>
                  {primeExclusivePoints.map((point, idx) => (
                      <div key={`prime-${idx}`} className="fv_business_intro_mobile_item mb-2">
                          {t(point)}
                      </div>
                  ))}
              </div>
          </div>

    </>
  )
}

export default BusinessContent