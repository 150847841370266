import React, { useState, useEffect } from 'react';
import { MaskedInput, createDefaultMaskGenerator } from 'react-hook-mask';
import { doGET } from '../../../../util/HttpUtil';
import "./styles.css"
import { useTranslation } from '../../../../store/context/TranslationContext';
function generateRandomString(length) {
    const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters.charAt(randomIndex);
    }
    return result;
}

export default function SSNField({
    className,
    label = "Social Security Number",
    value: initialValue,
    onChange,
    isDisabled,
    notifyParent,
    showErr,
    required,
    group = "Individuals_Lite",
    err,
    applicationId,
}) {
    const [value, setValue] = useState(initialValue || '');
    const [error, setError] = useState(null);
    const [hasError, setHasError] = useState(false);
    const [loading, setLoading] = useState(false);
    const {t} = useTranslation()

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    useEffect(() => {
        setError(err);
        setHasError(showErr);
    }, [err, showErr]);

    const validateSSN = (ssn) => {
        const re = /^(?!000|666)[0-8][0-9]{2}(?!00)[0-9]{2}(?!0000)[0-9]{4}$/;
        return re.test(ssn.replace(/-/g, ''));
    };

    const handleChange = (newValue) => {
        setValue(newValue);
        onChange(newValue);
        setError(null);
        setHasError(false);
    };

    const validateSSNField = async () => {
        if (!value) return; // Skip validation if no SSN is entered

        if (!validateSSN(value)) {
            setError("Please enter a valid SSN");
            setHasError(true);
            notifyParent(false);
            return;
        }

        setLoading(true);
        const formattedSSN = value.replace(/-/g, ''); // Remove hyphens for API call
        const response = await doGET(`/api/cyclos/verify/field?group=${group}&value=${formattedSSN}&field=Individual_SSN&applicationId=${applicationId}`);
        setLoading(false);

        if (response.status === 204) {
            notifyParent(true);
            setError(null);
            setHasError(false);
        } else if (response.status === 200 && response.data) {
            setError(response.data);
            setHasError(true);
            notifyParent(false);
        }
    };

    const maskGenerator = createDefaultMaskGenerator('999-99-9999');
    const id = generateRandomString(36);

    return (
        <div className={`${className} ${(error & hasError) ? 'error' : ''} mt-2`}>
            <div className='inputfield__container'>
                <div className='inputfield__innercontainer_outline'>
                    <Label id={id} label={t(label)} required={required} />
                    <div className={`inputfield px-0`} style={{ margin: "0px 0px 5px 0px", height: "50px" }}>
                        <MaskedInput
                            id={id}
                            className="inputfield__input"
                            maskGenerator={maskGenerator}
                            value={value}
                            onChange={handleChange}
                            onBlur={validateSSNField}
                            placeholder="XXX-XX-XXXX"
                            disabled={isDisabled}
                            style={{
                                height: "50px",
                                borderRadius: "15px",
                                paddingLeft: "15px",
                                border: "none",
                                fontSize: "14px",
                                outline: "none",
                                backgroundColor: "#F1FAFC",
                                width: "100%",
                            }}
                        />
                    </div>
                </div>
            </div>
            {(error?.length && hasError) ? <em className="selectfield__error">{t(error)}</em> : null}
            {loading && <div className="loading mt-3">{t("Validating...")}</div>}
        </div>
    );
}

const Label = ({ label, required, id }) => {
    return (
            <label htmlFor="username"
                style={{
                    minWidth: "120px",
                    fontSize: 16,
                    margin: "0px 0px 5px 5px",
                    fontWeight: "500",
                    textAlign: "left",
                    display: 'block',
                    marginBottom: '5px'
                }}>
                {label ?? "Username"}
                {required && <font color="red">*</font>}
            </label>
    );
};