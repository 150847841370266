import React, { useContext, useEffect, useState } from 'react';
import { FaRegUserCircle, FaDollarSign, FaMobileAlt, FaRegListAlt, FaTools } from 'react-icons/fa';
import '../IndividualLite.scss';
import Logo from "../../../../assets/images/Logo.svg";
import { useNavigate } from 'react-router-dom';
import ButtonGroup from '../BusinessLiteForms/components/ButtonGroup';
import { DialogContext } from '../../../../store/context/DialogContext';
import BusinessContent from '../BusinessLiteForms/BusinessContent';

const ApplyBusinessPrimeContent = () => {
    const { showError } = useContext(DialogContext)

    const navigate = useNavigate();
    const [sz, setSz] = useState(18);
    const t = (v) => v

    useEffect(() => {
        if (window.screen.width < 550) setSz(30);

        if (localStorage?.getItem("error")) {
            showError(localStorage?.getItem("error"))
            localStorage.setItem("error", "")
        }
    }, [])
    return (
        <div className="">
            <div className="fv_business_intro_container container py-4">
                {/* Desktop Layout (md and up) */}
                <div className='d-flex flex-column justify-content-center align-items-center'>
                    <img
                        src={Logo}
                        alt="fv-bank"
                        height="50"
                        className="logo logo-dark"
                    />
                    <div className="fv_indi-title">Business Prime</div>
                </div>


                <BusinessContent />

                <div className='mt-2'>
                    <ButtonGroup hideBackButton onNextClick={() => { navigate("/business-prime/login") }} onBackClick={() => { navigate(-1) }} step={2} asLabel={false} loading={false} limit={10} />
                </div>
            </div>
        </div>
    );
};

export default ApplyBusinessPrimeContent;