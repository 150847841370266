export const REQUIRED_FIELDS = {
    1: [],
    2: [
        "Business_Name",
        "Doing_Business_As",
        "address.buildingNumber",
        "address.street",
        "address.city",
        "address.state",
        "address.pinCode",
        "address.country",
        "Business_Registration_Country",
        "entityType",
        "Business_Registration_Number",
        "Business_Formation_Date",
        "Business_Industry_Type",
        "empCount",
        "BUS_Source_Of_Funds",
        "liquidFunds",
        "hasEntity",
    ],
    3: ["Primary_Business_Activities", "Business_Product_Services", "Customer_Description", "Business_Plan_Disclosure", "Illegal_Immoral_Disclosure", "Deposit_Reasons", "Payment_Reasons", "Deposit_Locations", "Payment_Locations"],
    4: ["primaryBusinessModel", "Regulatory_Agency", "talkToSales"],
    5: [],
    6: ["hearAbtUs", "showName", "refName"],
    7: ["firstName", "lastName", "Date_Of_Birth"],
}


export const agreements = [
    { key: 'bridgeAuthorization', label: 'Bridge Authorization Agreement and Terms' },
    { key: 'depositTerms', label: 'Deposit Terms and Conditions' },
    { key: 'onlineBankingTerms', label: 'Online Banking Terms and Conditions' },
    { key: 'privacyPolicy', label: 'Privacy Policy' },
    { key: 'standardFees', label: 'Standard Fees' },
];

export const GroupedFields = [
    // addressList
    'addressList[].type',
    'addressList[].buildingNumber',
    'addressList[].street',
    'addressList[].city',
    'addressList[].state',
    'addressList[].pinCode',
    'addressList[].country',

    //individuals
    'individuals[].firstName',
    'individuals[].middleName',
    'individuals[].lastName',
    'individuals[].address.buildingNumber',
    'individuals[].address.street',
    'individuals[].address.city',
    'individuals[].address.state',
    'individuals[].address.pinCode',
    'individuals[].address.country',
    'individuals[].mobilePhone',
    'individuals[].dob',
    'individuals[].nationality',
    'individuals[].taxId',
    'individuals[].isPrimaryUser',
    'individuals[].email',
    'individuals[].Mother_Maiden_Name',
    'individuals[].accessType',
    'individuals[].role',

    //shareholder Fields
    'individuals[].sourceOfWealthList',
    'individuals[].percentOwner',


    //auth user fields
    'individuals[].isAuthorizedContact',
    'individuals[].isAgentWithAttorneyPower',
    'individuals[].isAuthorizedUser',
    'individuals[].isShareHolder',
    'individuals[].haveControlOverEntity',
    'individuals[].isDirector',

    //entitiy Fields
    "entities[].relations",
    "entities[].name",
    "entities[].incorpCountry",
    'entities[].businessNature',
    "entities[].percentOwner",
    "entities[].sourceOfWealthList",
    "entities[].website",
    "entities[].yearOfIncorporation",

]

export const cleanupMappings = [
    {
        conditionPath: 'website',
        conditionValue: false,
        keys: ['url']
    },
    {
        conditionPath: 'website',
        conditionValue: true,
        keys: ['engage']
    },
    {
        conditionPath: 'inOperation',
        conditionValue: false,
        keys: ['operationStartDate']
    },
    {
        conditionPath: 'inOperation',
        conditionValue: true,
        keys: ['operateEstDate']
    },
    {
        conditionPath: 'Bank_Account_Another_Financial_Institution',
        conditionValue: false,
        keys: ['additionalBankAccountInstName']
    },
    {
        conditionPath: 'penalty',
        conditionValue: false,
        keys: ['penaltyDetails']
    },
    {
        conditionPath: 'litigation',
        conditionValue: false,
        keys: ['litigationDetails']
    },
    {
        conditionPath: 'Business_Registration_Country',
        conditionValue: "US",
        keys: ['Business_Tax_ID'],
        conditionOperator: '==='
    },
    {
        conditionPath: 'Business_Registration_Country',
        conditionValue: "US",
        keys: ['EIN'],
        conditionOperator: '!=='
    },
    {
        conditionPath: 'Money_Services_Business',
        conditionValue: false,
        keys: ['primaryBusinessModel']
    },
    {
        conditionPath: 'Regulated_Disclosure',
        conditionValue: false,
        keys: ['Regulatory_Agency']
    },
];

export const addFieldsMapping = [
      {
        conditionPath: 'hasEntity',
        conditionValue: true,
        addValues: [
            { key: 'entities', value: [{}] }
        ],
        conditionOperator: '=='
    },

];

export const addressDesc = {
    buildingNumber: "E.g. APT 302, Avalon Apartments",
    street: "E.g. 1600 Pennsylvania Avenue NW.",
    city: "E.g. San Fernando del Valle de Catamarca",
    pinCode: "E.g. 110001.",
    state: "E.g. Zhejiang Province",
    country: "E.g. China"
};
