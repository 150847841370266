import React from 'react';
import { Col, Row } from 'reactstrap';
import ButtonGroup from '../../BusinessLiteForms/components/ButtonGroup';
import { WrapperField } from '../../../../../components/InputField/WrapperField';



const AccountActivity = ({ asLabel, editMode,  data, handleChange, pageNum, loading, step, decrementStep, handleSubmit, bPrimeJSON, showErr, setErrors }) => {

    return (
        <>
            <div className='mb-3'>
                <h2 style={{ fontWeight: 'bolder' }} className='pt-3'>Expected Account Activity</h2>

                <Row>
                    <Col className="col-xl-6 col-12">
                        <WrapperField
                            disabled={asLabel}
                            jsonField={bPrimeJSON["Amount_Of_Initial_Deposit"]}
                            dataObj={data}
                            onChange={(updatedData) => handleChange(updatedData)}
                            showError={showErr}
                            onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, Amount_Of_Initial_Deposit: errorMsg }))}
                        />

                    </Col>
                    <Col className="col-xl-6 col-12">

                        <WrapperField
                            disabled={asLabel}
                            jsonField={bPrimeJSON["Average_Balance"]}
                            dataObj={data}
                            onChange={(updatedData) => handleChange(updatedData)}
                            showError={showErr}
                            onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, Average_Balance: errorMsg }))}
                        />

                    </Col>
                </Row>

                <Row>
                    <Col className="col-xl-6 col-12">
                        <WrapperField
                            disabled={asLabel}
                            jsonField={bPrimeJSON["Number_Of_Monthly_Ach_Deposits"]}
                            dataObj={data}
                            onChange={(updatedData) => handleChange(updatedData)}
                            showError={showErr}
                            onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, Number_Of_Monthly_Ach_Deposits: errorMsg }))}
                        />

                    </Col>
                    <Col className="col-xl-6 col-12">
                        <WrapperField
                            disabled={asLabel}
                            jsonField={bPrimeJSON["Amount_Of_Monthly_Ach_Deposits"]}
                            dataObj={data}
                            onChange={(updatedData) => handleChange(updatedData)}
                            showError={showErr}
                            onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, Amount_Of_Monthly_Ach_Deposits: errorMsg }))}
                        />

                    </Col>
                </Row>

                <Row>
                    <Col className="col-xl-6 col-12">

                        <WrapperField
                            disabled={asLabel}
                            jsonField={bPrimeJSON["Number_Of_Monthly_Domestic_Wire_Deposits"]}
                            dataObj={data}
                            onChange={(updatedData) => handleChange(updatedData)}
                            showError={showErr}
                            onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, Number_Of_Monthly_Domestic_Wire_Deposits: errorMsg }))}
                        />

                    </Col>
                    <Col className="col-xl-6 col-12">

                        <WrapperField
                            disabled={asLabel}
                            jsonField={bPrimeJSON["Amount_Of_Monthly_Domestic_Wire_Deposits"]}
                            dataObj={data}
                            onChange={(updatedData) => handleChange(updatedData)}
                            showError={showErr}
                            onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, Amount_Of_Monthly_Domestic_Wire_Deposits: errorMsg }))}
                        />

                    </Col>
                </Row>

                <Row>
                    <Col className="col-xl-6 col-12">
                        <WrapperField
                            disabled={asLabel}
                            jsonField={bPrimeJSON["Number_Of_Monthly_International_Wire_Deposits"]}
                            dataObj={data}
                            onChange={(updatedData) => handleChange(updatedData)}
                            showError={showErr}
                            onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, Number_Of_Monthly_International_Wire_Deposits: errorMsg }))}
                        />

                    </Col>
                    <Col className="col-xl-6 col-12">
                        <WrapperField
                            disabled={asLabel}
                            jsonField={bPrimeJSON["Amount_Of_Monthly_International_Wire_Deposits"]}
                            dataObj={data}
                            onChange={(updatedData) => handleChange(updatedData)}
                            showError={showErr}
                            onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, Amount_Of_Monthly_International_Wire_Deposits: errorMsg }))}
                        />

                    </Col>
                </Row>

                <Row>
                    <Col className="col-xl-6 col-12">
                        <WrapperField
                            disabled={asLabel}
                            jsonField={bPrimeJSON["Number_Of_Monthly_Ach_Payments"]}
                            dataObj={data}
                            onChange={(updatedData) => handleChange(updatedData)}
                            showError={showErr}
                            onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, Number_Of_Monthly_Ach_Payments: errorMsg }))}
                        />

                    </Col>
                    <Col className="col-xl-6 col-12">
                        <WrapperField
                            disabled={asLabel}
                            jsonField={bPrimeJSON["Amount_Of_Monthly_Ach_Payments"]}
                            dataObj={data}
                            onChange={(updatedData) => handleChange(updatedData)}
                            showError={showErr}
                            onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, Amount_Of_Monthly_Ach_Payments: errorMsg }))}
                        />

                    </Col>
                </Row>

                <Row>
                    <Col className="col-xl-6 col-12">
                        <WrapperField
                            disabled={asLabel}
                            jsonField={bPrimeJSON["Number_Of_Monthly_Domestic_Wire_Payments"]}
                            dataObj={data}
                            onChange={(updatedData) => handleChange(updatedData)}
                            showError={showErr}
                            onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, Number_Of_Monthly_Domestic_Wire_Payments: errorMsg }))}
                        />

                    </Col>
                    <Col className="col-xl-6 col-12">
                        <WrapperField
                            disabled={asLabel}
                            jsonField={bPrimeJSON["Amount_Of_Monthly_Domestic_Wire_Payments"]}
                            dataObj={data}
                            onChange={(updatedData) => handleChange(updatedData)}
                            showError={showErr}
                            onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, Amount_Of_Monthly_Domestic_Wire_Payments: errorMsg }))}
                        />

                    </Col>
                </Row>

                <Row>
                    <Col className="col-xl-6 col-12">
                        <WrapperField
                            disabled={asLabel}
                            jsonField={bPrimeJSON["Number_Of_Monthly_International_Wire_Payments"]}
                            dataObj={data}
                            onChange={(updatedData) => handleChange(updatedData)}
                            showError={showErr}
                            onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, Number_Of_Monthly_International_Wire_Payments: errorMsg }))}
                        />

                    </Col>
                    <Col className="col-xl-6 col-12">
                        <WrapperField
                            disabled={asLabel}
                            jsonField={bPrimeJSON["Amount_Of_Monthly_International_Wire_Payments"]}
                            dataObj={data}
                            onChange={(updatedData) => handleChange(updatedData)}
                            showError={showErr}
                            onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, Amount_Of_Monthly_International_Wire_Payments: errorMsg }))}
                        />

                    </Col>
                </Row>

                <WrapperField
                    disabled={asLabel}
                    jsonField={bPrimeJSON["paymentForms"]}
                    dataObj={data}
                    onChange={(updatedData) => handleChange(updatedData)}
                    showError={showErr}
                    onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, paymentForms: errorMsg }))}
                />




                <WrapperField
                    disabled={asLabel}
                    jsonField={bPrimeJSON["Deposit_Reasons"]}
                    dataObj={data}
                    onChange={(updatedData) => handleChange(updatedData)}
                    showError={showErr}
                    onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, Deposit_Reasons: errorMsg }))}
                />



                <WrapperField
                    disabled={asLabel}
                    jsonField={bPrimeJSON["Payment_Reasons"]}
                    dataObj={data}
                    onChange={(updatedData) => handleChange(updatedData)}
                    showError={showErr}
                    onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, Payment_Reasons: errorMsg }))}
                />



                <Row>
                    <Col className="col-xl-6 col-12">

                        <WrapperField
                            disabled={asLabel}
                            jsonField={bPrimeJSON["Deposit_Locations"]}
                            dataObj={data}
                            onChange={(updatedData) => handleChange(updatedData)}
                            showError={showErr}
                            onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, Deposit_Locations: errorMsg }))}
                        />

                    </Col>
                    <Col className="col-xl-6 col-12">
                        <WrapperField
                            disabled={asLabel}
                            jsonField={bPrimeJSON["Payment_Locations"]}
                            dataObj={data}
                            onChange={(updatedData) => handleChange(updatedData)}
                            showError={showErr}
                            onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, Payment_Locations: errorMsg }))}
                        />

                    </Col>
                </Row>


                <WrapperField
                    disabled={asLabel}
                    jsonField={bPrimeJSON["Interaction_With_South_American_Countries"]}
                    dataObj={data}
                    onChange={(updatedData) => handleChange(updatedData)}
                    showError={showErr}
                    onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, Interaction_With_South_American_Countries: errorMsg }))}
                />


                <WrapperField
                    disabled={asLabel}
                    jsonField={bPrimeJSON["Interaction_With_Central_American_Countries"]}
                    dataObj={data}
                    onChange={(updatedData) => handleChange(updatedData)}
                    showError={showErr}
                    onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, Interaction_With_Central_American_Countries: errorMsg }))}
                />


                <WrapperField
                    disabled={asLabel}
                    jsonField={bPrimeJSON["Interaction_With_Caribbean_Countries"]}
                    dataObj={data}
                    onChange={(updatedData) => handleChange(updatedData)}
                    showError={showErr}
                    onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, Interaction_With_Caribbean_Countries: errorMsg }))}
                />


                <WrapperField
                    disabled={asLabel}
                    jsonField={bPrimeJSON["caribbeanCountriesOther"]}
                    dataObj={data}
                    onChange={(updatedData) => handleChange(updatedData)}
                    showError={showErr}
                    onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, caribbeanCountriesOther: errorMsg }))}
                />



                <WrapperField
                    disabled={asLabel}
                    jsonField={bPrimeJSON["internationalCustomers"]}
                    dataObj={data}
                    onChange={(updatedData) => handleChange(updatedData)}
                    showError={showErr}
                    onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, internationalCustomers: errorMsg }))}
                />

                <WrapperField
                    disabled={asLabel}
                    jsonField={bPrimeJSON["hasCoinConversionPlan"]}
                    dataObj={data}
                    onChange={(updatedData) => handleChange(updatedData)}
                    showError={showErr}
                    onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, hasCoinConversionPlan: errorMsg }))}
                />


                <Row>
                    <Col className="col-xl-6 col-12">
                        <WrapperField
                            disabled={asLabel}
                            jsonField={bPrimeJSON["Count_Of_Monthly_Stablecoin_Conversions"]}
                            dataObj={data}
                            onChange={(updatedData) => handleChange(updatedData)}
                            showError={showErr}
                            onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, Count_Of_Monthly_Stablecoin_Conversions: errorMsg }))}
                        />
                    </Col>
                    <Col className="col-xl-6 col-12">
                        <WrapperField
                            disabled={asLabel}
                            jsonField={bPrimeJSON["Amount_Of_Monthly_Stablecoin_Conversions"]}
                            dataObj={data}
                            onChange={(updatedData) => handleChange(updatedData)}
                            showError={showErr}
                            onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, Amount_Of_Monthly_Stablecoin_Conversions: errorMsg }))}
                        />
                    </Col>

                </Row>




                <WrapperField
                    disabled={asLabel}
                    jsonField={bPrimeJSON["hasCryptoConversionPlan"]}
                    dataObj={data}
                    onChange={(updatedData) => handleChange(updatedData)}
                    showError={showErr}
                    onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, hasCryptoConversionPlan: errorMsg }))}
                />





                <Row>
                    <Col className="col-xl-6 col-12">
                        <WrapperField
                            disabled={asLabel}
                            jsonField={bPrimeJSON["Count_Of_Monthly_Cryptocurrency_Conversions"]}
                            dataObj={data}
                            onChange={(updatedData) => handleChange(updatedData)}
                            showError={showErr}
                            onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, Count_Of_Monthly_Cryptocurrency_Conversions: errorMsg }))}
                        />

                    </Col>
                    <Col className="col-xl-6 col-12">
                        <WrapperField
                            disabled={asLabel}
                            jsonField={bPrimeJSON["Amount_Of_Monthly_Cryptocurrency_Conversions"]}
                            dataObj={data}
                            onChange={(updatedData) => handleChange(updatedData)}
                            showError={showErr}
                            onError={(errorMsg) => setErrors((prevErrors) => ({ ...prevErrors, Amount_Of_Monthly_Cryptocurrency_Conversions: errorMsg }))}
                        />

                    </Col>
                </Row>
            </div>
            <ButtonGroup
                pageNum={pageNum}
                showPageNum={true}
                loading={loading}
                asLabel={asLabel || editMode}
                limit={10}
                onNextClick={() => handleSubmit({ isLastStep: false })}
                step={step}
                onBackClick={decrementStep}
            />
        </>
    )
}

export default AccountActivity;
