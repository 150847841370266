import { ThreeBounce } from "better-react-spinkit";
import React, { useContext, useEffect, useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import OtpInput from "../../pages/Authentication/OtpInput";
import { DialogContext } from "../../store/context/DialogContext";
import { useTranslation } from "../../store/context/TranslationContext";
import { doGET, doPOST, doPUT } from "../../util/HttpUtil";
import PhoneNumWithValidation from "./PhoneNumWithValidation";
import "./styles.css";


const btnStyle = {
  backgroundColor: '#1678AE',
  height: "40px",
  padding: "0 16px",
  fontSize: "16px",
  borderRadius: "10px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#FFF"
};

export default function PhoneWithOtpValidation({
  className,
  label = "Mobile Phone",
  value,
  onChange,
  isDisabled,
  formData,
  showErr,
  required,
  group = "Individuals_Lite",
  form_id,
  onError = () => { },
  shouldPerformOtpVerification = true,
  type,
  onOtpVerify = () => { },
  placeholder = "Mobile Phone",
}) {
  const [captcha, setCaptcha] = useState("");
  const [captchaId, setCaptchaId] = useState(null);
  const [captchaImage, setCaptchaImage] = useState(null);
  const [captchaError, setCaptchaError] = useState(null);
  const [resendTimer, setResendTimer] = useState(0);

  const [otp, setOtp] = useState('');
  const [otpId, setOtpId] = useState(null);
  const [otpError, setOtpError] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const { showError, showMessage } = useContext(DialogContext);
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const { t } = useTranslation();

  const toggleModal = () => { reset({ refreshCaptcha: false }); setModalOpen(!modalOpen) };

  useEffect(() => {
    if (resendTimer > 0) {
      const timer = setTimeout(() => setResendTimer(resendTimer - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [resendTimer]);

  const fetchCaptcha = async () => {
    try {
      setLoading(true);
      const response = await doPOST("/api/o/otp/create", { for: "captcha" });
      setCaptchaImage(response.data.image);
      setCaptchaId(response.data.captcha_id);
    } catch (error) {
      showError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSendOtp = async () => {
    if (!captcha) {
      setCaptchaError("Please solve the CAPTCHA.");
      return;
    }
    setLoading(true);
    try {
      const fullPhoneNumber = `${value.countryCode}${value.phone}`;
      const response = await doGET(`/api/message/send/otp?to=${fullPhoneNumber}&ref_id=${form_id}&platform=web&captchaOTP_Id=${captchaId}&captcha=${captcha}&individual_id=${form_id}`);
      if (response?.data?._id) {
        setOtpId(response.data._id);
        showMessage("OTP sent successfully!");
        setModalOpen(true);
        setOtpError("");

      } else {
        showError("Failed to send OTP. Please try again.");
        reset({ refreshCaptcha: true })
      }
    } catch (error) {
      showError(error);
      reset({ refreshCaptcha: true })
    } finally {
      setLoading(false);
    }
  };

  const handleOtpVerification = async () => {
    if (otp.length !== 4) {
      setOtpError("Please enter a 4-digit OTP.");
      return;
    }
    setLoading(true);
    try {
      const response = await doPUT(
        `/api/${group == "Businesses_Lite" ? "business-lite" : "individual-lite"}/update?otp_id=${otpId}&otpValue=${otp}`,
        { ...(formData ?? {}), mobilePhone: value?.phone, mobileCountryCode: value?.countryCode }
      );
      if (response.status === 200) {
        showMessage("OTP verified successfully!");
        onChange({
          ...value,
          phoneVerifiedViaOTP: true
        })
        onError({ errorMsg: "", hasError: false });
        onOtpVerify()
        setModalOpen(false);
      } else {
        setOtpError("Invalid OTP. Please try again.");
        reset({ refreshCaptcha: true })
      }
    } catch (error) {
      showError(error);
      reset({ refreshCaptcha: true })
    } finally {
      setLoading(false);
    }
  };

  const handleOtpChange = (newOtp) => {
    setOtp(newOtp);
    setOtpError(null);
  };

  const hasValue = () => {
    return value?.countryCode && value?.phone
  }


  const showCaptcha = () => {
    setModalOpen(true)
    fetchCaptcha()
  }

  const reset = ({ refreshCaptcha = true }) => {
    setCaptcha("");
    setCaptchaId(null);
    setCaptchaImage(null);
    setCaptchaError(null);
    setOtp("");
    setOtpId(null);
    setOtpError(null);
    if (refreshCaptcha) {
      fetchCaptcha();
    }
    setResendTimer(0);
  }

  return (
    <div className={`${className} fv_phone-otp-validation`}>
      <div className="fv_phone-validation-container">
        <div className="flex-1">
          <PhoneNumWithValidation
            label={label}
            value={value}
            onChange={(updatedValue) => {
              onChange({ ...updatedValue, phoneVerifiedViaOTP: false });
              setIsValid(false);
            }}
            isDisabled={isDisabled || (shouldPerformOtpVerification && value?.phoneVerifiedViaOTP && value?.phone)}
            notifyParent={(isValid) => setIsValid(isValid)}
            onError={(error) => {
              if (error?.hasError) setIsValid(false); else setIsValid(true);
              onError(error)
            }}
            showErr={showErr}
            required={required}
            err={hasValue() ? "" : t("Please enter a valid Mobile Phone")}
            group={group}
            form_id={form_id}
            placeholder={placeholder}
          />
          {(shouldPerformOtpVerification && value?.phone && !value?.phoneVerifiedViaOTP && isValid) ?
            <div className="error-message mt-3"> <em className="selectfield__error">{t("Please verify mobile phone with OTP")}</em></div> : null}
        </div>
        {shouldPerformOtpVerification ? <>
          {value?.phoneVerifiedViaOTP ? (
            <FaCheckCircle color="#28a745" size={20} title="OTP Verified" style={{ marginLeft: "10px", marginTop: '50px' }} />
          ) : (
            <Button
              style={btnStyle}
              onClick={showCaptcha}
              disabled={loading || !value.phone || !value.countryCode || !isValid}
              className="fv_send-otp-button"
            >
              {loading ? <ThreeBounce size={10} color="#FFFFFF" /> : t("Send OTP")}
            </Button>
          )}</> : null}

      </div>

      {shouldPerformOtpVerification ?
        <Modal
          backdrop="static"
          isOpen={modalOpen}
          toggle={toggleModal}
          centered
          className="fv_modal-content"
        >

          <ModalBody className="fv_modal-body pt-4">

            {!otpId ? <>
              <p>{t("Solve the CAPTCHA to send OTP")}</p>
              <div className="d-flex flex-column gap-2 flex-sm-row justify-content-center">
                <div
                  style={{
                    border: "1px solid #C6F3FF",
                    borderRadius: "14px",
                    backgroundColor: "#F1FAFC"
                  }}
                  className=" d-flex align-items-center  justify-content-between  me-2 "
                >
                  {captchaImage ? (
                    <img
                      src={`data:image/jpeg;base64,${captchaImage}`}
                      alt="CAPTCHA"
                      style={{
                        objectFit: "contain",
                        height: "30px"
                      }}
                    />
                  ) : (
                    <ThreeBounce size={15} color="#5fabd5" />
                  )}

                  <i
                    id="captcha"
                    onClick={() => fetchCaptcha()}
                    style={{
                      fontSize: "25px",
                      cursor: "pointer",
                    }}
                    className="uil-redo rounded-right me-1  "
                  />
                </div>
                <div>

                  <OtpInput
                    value={captcha}
                    onChange={(v) => {
                      setCaptcha(v);
                    }}
                    error={captchaError}
                    className="flex-1 mb-1 px-0"
                    style={{ borderRadius: "14px", marginRight: "6px", border: "1px solid #C6F3FF", height: "40px", }}
                  />


                  {captchaError && <p className="error-text">{captchaError}</p>}

                </div>

              </div>
            </> :
              <>
                <p className="pb-3">{t("Enter the OTP sent to")} {value.countryCode}-{value.phone}</p>
                <div className="fv_otp-input-container">
                  <OtpInput
                    value={otp}
                    onChange={handleOtpChange}
                    style={{ borderRadius: "14px", marginRight: "6px", border: "1px solid #C6F3FF", height: "40px", }}

                  />
                </div>
                {otpError && <div className="fv_error-message">{t(otpError)}</div>}
              </>}

          </ModalBody>
          <ModalFooter className="fv_modal-footer">
            {otpId ? <Button
              style={btnStyle}
              onClick={handleOtpVerification}
              disabled={otp.length !== 4 || loading}
              className="fv_btn-primary"
            >
              {loading ? <ThreeBounce size={10} color="#FFFFFF" /> : t("Verify OTP")}
            </Button> : <> <Button
              style={btnStyle}
              onClick={handleSendOtp}
              disabled={loading || resendTimer > 0}
            >
              {loading ? <ThreeBounce size={10} color="#FFF" /> : t("Send OTP")}
            </Button>
              {resendTimer > 0 && (
                <p className="timer-text">Resend available in {resendTimer}s</p>
              )}</>}

            <Button
              color="secondary"
              onClick={toggleModal}
              className="fv_btn-secondary"
              style={{
                backgroundColor: "#f1faff",
                color: "#007bff",
                border: "1px solid #c6f3ff",
                borderRadius: "10px",
                padding: "8px 16px",
                fontSize: "16px"
              }}
            >
              {t("Cancel")}
            </Button>
          </ModalFooter>
        </Modal>
        : null}
    </div>
  );
}
